module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/web","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OctoBrowser","short_name":"OctoBrowser","description":"OctoBrowser описание.","lang":"ru","display":"standalone","icon":"./src/assets/images/icon.png","start_url":"/","background_color":"#0d4780","theme_color":"#fff","localize":[{"start_url":"/en/","lang":"en","name":"OctoBrowser","short_name":"OctoBrowser","description":"OctoBrowser description."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d40fd937f6d1863b484ec4129d9343d0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://octobrowser.net/","localeJsonSourceName":"locale","languages":["en","ru","pt"],"defaultLanguage":"en","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"nonExplicitSupportedLngs":true},"redirect":true,"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":true,"languages":["en"]},{"matchPath":"/en/","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-M4KDFQX","cookieName":"oc_gpdr","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://octobrowser.net/","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
